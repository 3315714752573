import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from "react";
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import metamaskSDK from '@web3-onboard/metamask'
import { ethers, JsonRpcProvider } from 'ethers'

// Contracts
const PLP_ROUTER_ADDRESS = "0xfcfb187ef444d7b01253bb4faf862c32b36e5ab8";
const WRAPPED_ETHER_ADDRESS = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
const SEPOLIA_USDC_ADDRESS = "0x1c7d4b196cb0c7b01d743fbc6116a902379c7238";

// PurchaseTypes
const PURCHASE_TYPE_HOLD = 0;
const PURCHASE_TYPE_BUY_WITH_ETH = 1;
const PURCHASE_TYPE_BUY_WITH_TOKENS = 2;

// URLs
const RPC_MAINNET = "https://ethereum-rpc.publicnode.com";
const RPC_TESTNET = "https://ethereum-sepolia-rpc.publicnode.com";
const RPC_URL = RPC_MAINNET;
const APP_METADATA_NAME = "PayLink Protocol";
const APP_METADATA_URL = "https://www.paylink.xyz";

function App() {

  const [tokenPrice, setTokenPrice] = useState<string | null>(null);
  const [userData, setUserData] = useState<[number, number]>([0, 0]);

  useEffect(() => {

    function decodeLink() {
      const path = window.location.pathname;
      const pathParts = path.split("/").filter(Boolean);
      if (pathParts.length == 0 || pathParts.length > 1) {
        setUserData([0, 0])
      }
      const firstParam = pathParts[0];
      console.log("Raw Param: ", firstParam);
      const params = decodePayLinkData(firstParam); // Sample Data: paylink.xyz/10j8pdfwjx2y69us2yul
      console.log("Decoded: ", params);
      setUserData(params)
    }
    decodeLink();

    async function getPrice() {
      const price = await fetchTokenPrice("0x516d339afa72f6959b8e06a31fbc32da3e49348b");
      setTokenPrice(price);
    }
    getPrice();

  }, []);

  if (Number.isNaN(userData[0]) || Number.isNaN(userData[1])) {
    return buildErrorPage();
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Hello world! <code>src/App.tsx</code> and {userData[0]} and {userData[1]} get CNCT price: {tokenPrice}
          </p>
          <button onClick={connectToWallet}>Connect Wallet</button>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    );
  }

}

function buildErrorPage() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          404 Error not found!
        </p>
        <button onClick={connectToWallet}>Connect Wallet</button>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

async function connectToWallet() {

  const metamaskSDKWallet = metamaskSDK({options: {
	  extensionOnly: true,
	  dappMetadata: {
		name: APP_METADATA_NAME,
		url: APP_METADATA_URL
	  }
	}})

  /*
	const walletConnect = walletConnectModule({
		projectId: '08a6c3b5da527001bbf3b39e8a96717f',
		requiredChains: [1],
		dappUrl: APP_METADATA_URL
	})
  */

	const wallets = [ 
		metamaskSDKWallet,
		// walletConnect,
		injectedModule()
	]

	const chains = [
	  {
		id: '0x1',
		token: 'ETH',
		label: 'Ethereum Mainnet',
		rpcUrl: RPC_MAINNET
	  },
	  {
		id: '0xaa36a7',
		token: 'ETH',
		label: 'Sepolia',
		rpcUrl: RPC_TESTNET
	  }
	]

	const appMetadata = {
	  name: APP_METADATA_NAME,
	  //icon: '',
    description: 'Connect your wallet to make purchases over PayLink.'
	}

	const onboard = Onboard({
		wallets,
		chains,
		appMetadata
	})

	const wallet = await onboard.connectWallet();
	
	let ethersProvider
	try {
		ethersProvider = new ethers.BrowserProvider(wallet[0].provider, 'any')
	} catch (exception) {
		console.log(exception)
		return;
	}
		
  const signer = await ethersProvider.getSigner()	

  //const purchaseTokenAddress = SEPOLIA_USDC_ADDRESS;
  const purchaseTokenAddress = WRAPPED_ETHER_ADDRESS;

  let decimals: number = 0; // Standardwert setzen

  const result = await fetchTokenDecimals(purchaseTokenAddress);
  if (result !== null) {
    decimals = result;
    console.log(`Decimals for Token: ${decimals}`);
  } else {
    console.log(`Decimals for Token are null!`);
    // Show Error Screen
    return;
  }

  const purchaseMakerWalletAddress = wallet[0].accounts[0].address;
  const purchaseAmount = ethers.parseUnits("0.001", decimals);

  if (purchaseTokenAddress.toLowerCase() !== WRAPPED_ETHER_ADDRESS.toLowerCase()) {
    const currentPurchaseMakerAllowance = await getTokenAllowance(purchaseTokenAddress, purchaseMakerWalletAddress, PLP_ROUTER_ADDRESS);
    const currentPurchaseMakerBalance = await getTokenBalance(purchaseTokenAddress, purchaseMakerWalletAddress);
    if (currentPurchaseMakerAllowance == null || currentPurchaseMakerBalance == null) {
      // Show Error Screen
      return;
    }
    if (currentPurchaseMakerAllowance <= currentPurchaseMakerBalance) {
      // Need to approve first
      await approveToken(signer, purchaseTokenAddress, PLP_ROUTER_ADDRESS, purchaseAmount);
    }
  }

  await purchaseToken(
    signer,
    1731, // **appId**
    44961816, // **userId**
    purchaseAmount, // **purchaseAmount (ETH als String)**
    purchaseTokenAddress, // **purchaseTokenAddress**
    wallet[0].accounts[0].address, // **Client Wallet Adresse**
    PURCHASE_TYPE_BUY_WITH_ETH, // **purchaseType**
    Math.floor(Date.now() / 1000) + 3600, // **expirationTimestamp (1h in Zukunft)**
    0 // **burnPercentage**
  )

}

const ERC20_ABI = [
  {
    "constant": true,
    "inputs": [],
    "name": "decimals",
    "outputs": [{ "internalType": "uint8", "name": "", "type": "uint8" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      { "internalType": "address", "name": "owner", "type": "address" }
    ],
    "name": "balanceOf",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      { "internalType": "address", "name": "owner", "type": "address" },
      { "internalType": "address", "name": "spender", "type": "address" }
    ],
    "name": "allowance",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [
      { "internalType": "address", "name": "spender", "type": "address" },
      { "internalType": "uint256", "name": "amount", "type": "uint256" }
    ],
    "name": "approve",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

const plpRouter_ABI = [
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint256", "name": "appId", "type": "uint256" },
          { "internalType": "uint256", "name": "userId", "type": "uint256" },
          { "internalType": "uint256", "name": "purchaseAmount", "type": "uint256" },
          { "internalType": "address", "name": "purchaseTokenAddress", "type": "address" },
          { "internalType": "address", "name": "clientWalletAddress", "type": "address" },
          { "internalType": "uint256", "name": "purchaseType", "type": "uint256" },
          { "internalType": "uint256", "name": "expirationTimestamp", "type": "uint256" },
          { "internalType": "uint256", "name": "burnPercentage", "type": "uint256" }
        ],
        "internalType": "struct PayLinkProtocolRouterV2.PurchaseData",
        "name": "data",
        "type": "tuple"
      }
    ],
    "name": "purchase",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
];

interface PurchaseData {
  appId: bigint;
  userId: bigint;
  purchaseAmount: bigint;
  purchaseTokenAddress: string;
  clientWalletAddress: string;
  purchaseType: bigint;
  expirationTimestamp: bigint;
  burnPercentage: bigint;
}

async function purchaseToken(
  signer: any,
  appId: number,
  userId: number,
  purchaseAmountInWei: bigint,
  purchaseTokenAddress: string,
  clientWalletAddress: string,
  purchaseType: number,
  expirationTimestamp: number,
  burnPercentage: number
): Promise<ethers.TransactionReceipt | null> {
  try {
    const contract = new ethers.Contract(PLP_ROUTER_ADDRESS, plpRouter_ABI, signer);

    const purchaseData: PurchaseData = {
      appId: BigInt(appId),
      userId: BigInt(userId),
      purchaseAmount: purchaseAmountInWei,
      purchaseTokenAddress,
      clientWalletAddress,
      purchaseType: BigInt(purchaseType),
      expirationTimestamp: BigInt(expirationTimestamp),
      burnPercentage: BigInt(burnPercentage)
    };

    const txOptions = purchaseType === PURCHASE_TYPE_BUY_WITH_ETH ? { value: purchaseData.purchaseAmount } : {};

    console.log("Send Transaction...", purchaseData);

    const txn = await contract.purchase(purchaseData, txOptions);
    console.log("Transaction sent:", txn.hash);

    const receipt = await txn.wait();
    console.log("Transaction confirmed:", receipt);

    return receipt;
  } catch (error) {
    console.error("Failure while purchasing: ", error);
    return null;
  }
}

async function approveToken(
  signer: any,
  tokenAddress: string,
  spender: string,
  amountInWei: bigint
): Promise<ethers.TransactionReceipt | null> {
  try {
    const contract = new ethers.Contract(tokenAddress, ERC20_ABI, signer);

    // **Transaktion senden**
    const txn = await contract.approve(spender, amountInWei);
    console.log("Approval-Transaktion gesendet:", txn.hash);

    // **Auf Bestätigung warten**
    const receipt = await txn.wait();
    console.log("Approval bestätigt:", receipt);

    return receipt;
  } catch (error) {
    console.error("Fehler bei Approval:", error);
    return null;
  }
}

async function fetchTokenDecimals(tokenAddress: string): Promise<number | null> {
  try {
    const provider = new JsonRpcProvider(RPC_URL);
    const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
    const decimals: number = await contract.decimals();
    return decimals;
  } catch (error) {
    return null;
  }
}

async function getTokenAllowance(
  tokenAddress: string,
  owner: string,
  spender: string
): Promise<bigint | null> {
  try {
    const provider = new JsonRpcProvider(RPC_URL);
    const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
    const allowance: bigint = await contract.allowance(owner, spender);
    console.log(`Allowance for ${spender}: ${allowance.toString()}`);
    return allowance;
  } catch (error) {
    console.error("Fehler beim Abrufen der Allowance:", error);
    return null;
  }
}

async function getTokenBalance(
  tokenAddress: string,
  walletAddress: string
): Promise<bigint | null> {
  try {
    const provider = new JsonRpcProvider(RPC_URL);

    const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);

    // Token-Balance holen (BigInt)
    const balance: bigint = await contract.balanceOf(walletAddress);

    // Decimals abrufen
    //const decimals: number = await contract.decimals();

    // Balance umrechnen in lesbare Zahl
    console.log(`Token Balance für ${walletAddress}: ${balance}`);
    return balance;
  } catch (error) {
    console.error("Fehler beim Abrufen der Token Balance:", error);
    return null;
  }
}

async function getEthBalance(walletAddress: string): Promise<number | null> {
  try {
    const provider = new JsonRpcProvider(RPC_URL);

    // ETH Balance abrufen (BigInt)
    const balance: bigint = await provider.getBalance(walletAddress);

    // Umrechnen in ETH
    const balanceFormatted = Number(ethers.formatEther(balance));
    console.log(`ETH Balance für ${walletAddress}: ${balanceFormatted}`);
    return balanceFormatted;
  } catch (error) {
    console.error("Fehler beim Abrufen der ETH Balance:", error);
    return null;
  }
}

async function fetchTokenPrice(tokenAddress: string) {
  try {
    const response = await fetch(
      "https://api.geckoterminal.com/api/v2/simple/networks/eth/token_price/" + tokenAddress + "?include_market_cap=false&include_24hr_vol=false"
    );
    const data = await response.json();
    const price = data?.data?.attributes?.token_prices?.[tokenAddress];
    if (price) {
      const num = parseFloat(price)
      let order = Math.floor(Math.log10(Math.abs(num)));
      let factor = Math.pow(10, -order + 3);
      const val = Math.round(num * factor) / factor;
      return "$" + val;
    }
  } catch (error) {
    console.error("Failed to fetch token price:", error);
  }
  return "-";
}

function decodePayLinkData(data: string): [number, number] {
  const len = parseInt(data.slice(0, 2), 10);
  const appId = parseInt(data.slice(2, 2 + len), 36);
  const userId = parseInt(data.slice(2 + len), 36);
  return [appId, userId];
}

function helloWorld() {
	return "Hello Bob!"
}

export default App;
